@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html,
#root {
  height: 100%;
  background-color: rgb(226, 226, 226);
}

html,
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  user-select: none;
}

svg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  touch-action: none;
}
